import Vue from 'vue'
import { ReadableStream } from 'web-streams-polyfill/ponyfill'
import $ from 'jquery'
import vueRouter from './router'

window.constants = require('./generated/constants')
window.maps = require('./generated/maps')

window.Bus = new Vue()
window.$ = window.jQuery = $
require('bootstrap')
require('slick-carousel')
require('./configuration')

if (!window.ReadableStream) {
  window.ReadableStream = ReadableStream
}

require('./components/bootstrap')

if ($('#spark-app').length > 0) {
  window.app = new Vue({
    el: '#spark-app',
    router: vueRouter,
    data () {
      return {
        user: Spark.state.user,
        currentTeam: Spark.state.currentTeam
      }
    }
  })
}

require('./landing-pages/site')

if (!Spark.hideNav) {
  require('./landing-pages/stripe-menu')
}
